<template>
    <div>
        <button class="d-none d-lg-block btn btn-toggle-side-bar btn-outline-dark"
                v-on:click="toggleSideBar">
            <font-awesome-icon icon="bars"></font-awesome-icon>
        </button>
        <button class="d-block d-lg-none btn btn-sm btn-toggle-side-bar btn-outline-dark"
                v-on:click="toggleSideBar">
            <font-awesome-icon icon="bars"></font-awesome-icon>
        </button>
    </div>
</template>

<script>
    export default {
        name: "ButtonToggleSideBar",
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {
            toggleSideBar () {
                this.$store.commit("layout/toggleSideBar");
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
