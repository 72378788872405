<template>
    <section class="pbl container-fluid d-flex flex-column h-100 p-2 py-lg-3 pl-lg-3 pr-lg-3">
        <div class="position-sticky title d-flex align-items-center pb-1 mb-3">
            <div class="d-flex d-xl-none align-items-center mr-3">
                <button-toggle-side-bar></button-toggle-side-bar>
            </div>
            <h1 class="d-block font-weight-bolder flex-grow-1 text-nowrap pl-xl-0 mb-0">
                Dashboard
            </h1>
            <div class="d-flex align-items-center">

            </div>
        </div>
        <div class="row">
            <function-card class="col-lg-6 col-xl-4 mb-4"
                           to="/clients">
                <template v-slot:icon>
                    <font-awesome-icon class="text-primary"
                                       v-bind:icon="['fad', 'users']">
                    </font-awesome-icon>
                </template>
                <h2 class="font-weight-bolder text-right mb-1">
                    Clients
                </h2>
                <p class="text-right text-secondary mb-2">
                    It is where your clients live. You can manage them here.
                </p>
                <h5 class="text-right mb-1">
                    <font-awesome-icon v-bind:icon="['far', 'arrow-right']"></font-awesome-icon>
                </h5>
            </function-card>

            <function-card class="col-lg-6 col-xl-4 mb-4"
                           to="/pbl">
                <template v-slot:icon>
                    <font-awesome-icon class="text-warning"
                                       v-bind:icon="['fad', 'badge-sheriff']">
                    </font-awesome-icon>
                </template>
                <h2 class="font-weight-bolder text-right mb-1">
                    Points, Badges, and Leaderboard
                </h2>
                <p class="text-right text-secondary mb-2">
                    This is where you can control your point schemes and badges. Leaderboards for each of them also live here.
                </p>
                <h5 class="text-right mb-1">
                    <font-awesome-icon v-bind:icon="['far', 'arrow-right']"></font-awesome-icon>
                </h5>
            </function-card>

            <function-card class="col-lg-6 col-xl-4 mb-4"
                           to="/settings">
                <template v-slot:icon>
                    <font-awesome-icon class="text-dark"
                                       v-bind:icon="['fad', 'cogs']">
                    </font-awesome-icon>
                </template>
                <h2 class="font-weight-bolder text-right mb-1">
                    Settings
                </h2>
                <p class="text-right text-secondary mb-2">
                    You can adjust your settings or reset your account here.
                </p>
                <h5 class="text-right mb-1">
                    <font-awesome-icon v-bind:icon="['far', 'arrow-right']"></font-awesome-icon>
                </h5>
            </function-card>
        </div>
    </section>
</template>

<script>
import ButtonToggleSideBar from "../components/layout/ButtonToggleSideBar";
import FunctionCard from "../components/Dashboard/FunctionCard";
export default {
    name: "Dashboard",
    components: {
        FunctionCard,
        ButtonToggleSideBar,
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
