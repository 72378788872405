<template>
    <div class="function-card-wrapper mb-3">
        <router-link class="function-card card text-decoration-none text-dark shadow-sm rounded-lg h-100"
                     v-bind:to="to">
            <div class="card-body d-flex flex-nowrap justify-content-between align-items-center py-2">
                <div class="icon pr-2 pr-lg-4">
                    <slot name="icon"></slot>
                </div>
                <div class="content">
                    <slot></slot>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "FunctionCard",
    props: {
        "to": {
            type: String,
            required: true,
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.function-card {
    .icon {
        font-size: 3rem;
    }
}
</style>
